var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "manager-container" },
    [
      _vm.hasSysAdminPermission
        ? _c("div", { staticClass: "left" }, [
            _c(
              "div",
              { staticClass: "title" },
              [
                _c("base-block-title", {
                  attrs: {
                    title: _vm.$t("company"),
                    "sub-title": _vm.companyList.length,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "content" },
              _vm._l(_vm.companyList, function (item) {
                return _c("company-item", {
                  key: item.id,
                  attrs: {
                    company: item,
                    active: item.id === _vm.currentCompany.id,
                  },
                  nativeOn: {
                    click: function ($event) {
                      _vm.currentCompany = item
                    },
                  },
                })
              }),
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "middle" }, [
        _c(
          "div",
          { staticClass: "middle-title" },
          [
            _c("base-block-title", {
              attrs: { title: _vm.$t("role"), "sub-title": _vm.total },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "middle-content" },
          _vm._l(_vm.roleList, function (item) {
            return _c(
              "span",
              {
                key: item.id,
                class: { active: item.id === _vm.currentRole.id },
                staticStyle: {
                  position: "relative",
                  border: "1px solid #DCDFE6",
                },
                on: {
                  click: function ($event) {
                    _vm.currentRole = item
                  },
                },
              },
              [_vm._v(" " + _vm._s(item.name) + " ")]
            )
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "right" }, [
        _c(
          "div",
          { staticClass: "title-button" },
          [
            _c("base-block-title", {
              attrs: {
                title: _vm.$t("permissionList"),
                "sub-title": _vm.currentRole.id ? _vm.currentRole.name : "",
                "sub-title2": _vm.companyStatus,
              },
            }),
            _c(
              "div",
              { staticClass: "button-block" },
              [
                _vm.hasPermission(_vm.$permissionFunctionCode.EDIT)
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", round: "", plain: "" },
                        on: { click: _vm.savePermission },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-block" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tableLoading,
                    expression: "tableLoading",
                  },
                ],
                attrs: {
                  data: _vm.currentRole.id ? _vm.displayMenuList : [],
                  "row-key": "id",
                  "default-expand-all": "",
                  height: "100%",
                  stripe: "",
                  "default-sort": { prop: "sort" },
                },
              },
              [
                _c("base-no-data", { attrs: { slot: "empty" }, slot: "empty" }),
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: _vm.$t("serialNum"),
                    align: "center",
                    width: "50",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "name",
                    label: _vm.$t("menuName"),
                    width: "230",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(_vm.$t(scope.row.name)))]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "left",
                    fixed: "right",
                    label: _vm.$t("permissionList"),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          !scope.row.children.length
                            ? _c(
                                "el-checkbox",
                                {
                                  staticStyle: { "margin-right": "15px" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleCheckAllChange(
                                        $event,
                                        scope.row
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.checkAll,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "checkAll", $$v)
                                    },
                                    expression: "scope.row.checkAll",
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("checkAll")) + " ")]
                              )
                            : _vm._e(),
                          _c(
                            "el-checkbox-group",
                            {
                              staticStyle: { display: "inline-block" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleFunctionChange(
                                    $event,
                                    scope.row
                                  )
                                },
                              },
                              model: {
                                value: scope.row.checkedFunctions,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "checkedFunctions", $$v)
                                },
                                expression: "scope.row.checkedFunctions",
                              },
                            },
                            _vm._l(scope.row.functions || [], function (item) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: item.id,
                                  staticStyle: { "margin-right": "15px" },
                                  attrs: { label: item.id },
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            }),
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.addDialogVisible
        ? _c("base-form-add-or-edit", {
            attrs: {
              visible: _vm.addDialogVisible,
              "url-save": _vm.saveRoleUrl,
              "url-update": _vm.saveRoleUrl,
              fields: _vm.fields,
              labelWidthCn: "100",
              labelWidthEn: "200",
            },
            on: {
              closed: function ($event) {
                _vm.addDialogVisible = false
              },
              "submit-success": function ($event) {
                return _vm.getUpdateInfo(_vm.currentCompany)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }